import React from 'react';

const Ballons = () => {
  return (
    <svg
      width="488"
      height="505"
      viewBox="0 0 488 505"
      fill="none"
      className="ballons"
    >
      <g clipPath="url(#clip0)">
        <path
          opacity="0.1"
          d="M465.495 439.081C460.925 441.44 456.136 443.478 451.412 445.509C433.959 452.887 416.243 460.33 397.493 462.862C362.304 467.612 325.96 454.751 291.369 462.753C262.387 469.45 238.419 489.973 210.105 499.113C196.94 503.361 183.242 505.026 169.422 505.026H167.611C165.9 505.004 164.201 504.961 162.515 504.897C148.502 504.37 134.432 502.294 120.695 499.64C77.3226 491.284 33.1795 475.968 0.0192566 447.771C96.573 430.083 295.464 402.966 465.495 439.081Z"
          fill="#252223"
        />
        <path
          d="M194.581 477C194.581 477 182.944 437.216 196.765 407.947C202.567 395.66 204.491 381.862 201.812 368.524C200.531 362.192 198.676 355.991 196.27 350"
          stroke="#535461"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M205 332.024C205 336.896 196.001 351 196.001 351C196.001 351 187.002 336.877 187.002 332.024C186.975 330.849 187.187 329.68 187.626 328.586C188.066 327.493 188.724 326.497 189.562 325.656C190.4 324.815 191.402 324.147 192.507 323.691C193.612 323.235 194.8 323 195.999 323C197.198 323 198.385 323.235 199.491 323.691C200.596 324.147 201.597 324.815 202.435 325.656C203.273 326.497 203.932 327.493 204.371 328.586C204.811 329.68 205.023 330.849 204.995 332.024H205Z"
          fill="#52BD43"
        />
        <path
          d="M219.703 358.66C217.059 362.82 202 370 202 370C202 370 202.275 353.067 204.919 348.908C206.216 346.976 208.203 345.637 210.456 345.176C212.709 344.715 215.049 345.169 216.978 346.44C218.907 347.712 220.272 349.701 220.781 351.983C221.29 354.265 220.903 356.66 219.703 358.655V358.66Z"
          fill="#52BD43"
        />
        <path
          d="M218.978 404.81C214.617 407.007 198 405.49 198 405.49C198 405.49 206.66 391.225 211.021 389.028C212.06 388.478 213.199 388.14 214.37 388.035C215.541 387.93 216.721 388.06 217.842 388.417C218.962 388.773 220 389.35 220.895 390.113C221.79 390.876 222.523 391.809 223.053 392.859C223.582 393.908 223.897 395.053 223.979 396.226C224.06 397.399 223.907 398.576 223.528 399.689C223.149 400.802 222.552 401.828 221.771 402.707C220.991 403.587 220.043 404.301 218.982 404.81H218.978Z"
          fill="#52BD43"
        />
        <path
          d="M210.39 439.829C206.5 442.701 190 444 190 444C190 444 196.074 428.634 199.964 425.758C200.889 425.063 201.942 424.558 203.063 424.272C204.184 423.986 205.351 423.925 206.496 424.091C207.641 424.258 208.742 424.649 209.735 425.242C210.728 425.835 211.593 426.618 212.281 427.547C212.969 428.476 213.467 429.531 213.744 430.653C214.022 431.774 214.074 432.939 213.898 434.081C213.722 435.223 213.322 436.319 212.72 437.305C212.118 438.292 211.326 439.149 210.39 439.829Z"
          fill="#52BD43"
        />
        <path
          d="M183.506 371.05C186.953 374.456 203 378 203 378C203 378 199.067 361.77 195.62 358.359C193.953 356.787 191.747 355.942 189.475 356.003C187.203 356.064 185.045 357.027 183.461 358.685C181.878 360.343 180.994 362.566 181 364.878C181.006 367.191 181.9 369.409 183.492 371.059L183.506 371.05Z"
          fill="#52BD43"
        />
        <path
          d="M174.022 411.81C178.383 414.007 195 412.49 195 412.49C195 412.49 186.34 398.225 181.979 396.028C180.94 395.478 179.801 395.14 178.63 395.035C177.459 394.93 176.279 395.06 175.158 395.417C174.038 395.774 173 396.35 172.105 397.113C171.21 397.876 170.477 398.809 169.947 399.859C169.418 400.908 169.103 402.053 169.021 403.226C168.94 404.399 169.093 405.576 169.472 406.689C169.851 407.802 170.448 408.828 171.229 409.707C172.009 410.587 172.957 411.301 174.018 411.81H174.022Z"
          fill="#52BD43"
        />
        <path
          d="M170.61 452.829C174.5 455.701 191 457 191 457C191 457 184.926 441.634 181.036 438.758C180.111 438.063 179.058 437.558 177.937 437.272C176.816 436.986 175.649 436.925 174.504 437.091C173.359 437.258 172.258 437.649 171.265 438.242C170.272 438.835 169.407 439.618 168.719 440.547C168.031 441.476 167.533 442.531 167.256 443.653C166.978 444.774 166.926 445.939 167.102 447.081C167.278 448.223 167.678 449.319 168.28 450.305C168.882 451.292 169.674 452.149 170.61 452.829Z"
          fill="#52BD43"
        />
        <path
          opacity="0.25"
          d="M205 332.024C205 336.896 196.001 351 196.001 351C196.001 351 187.002 336.877 187.002 332.024C186.975 330.849 187.187 329.68 187.626 328.586C188.066 327.493 188.724 326.497 189.562 325.656C190.4 324.815 191.402 324.147 192.507 323.691C193.612 323.235 194.8 323 195.999 323C197.198 323 198.385 323.235 199.491 323.691C200.596 324.147 201.597 324.815 202.435 325.656C203.273 326.497 203.932 327.493 204.371 328.586C204.811 329.68 205.023 330.849 204.995 332.024H205Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M219.703 358.66C217.059 362.82 202 370 202 370C202 370 202.275 353.067 204.919 348.908C206.216 346.976 208.203 345.637 210.456 345.176C212.709 344.715 215.049 345.169 216.978 346.44C218.907 347.712 220.272 349.701 220.781 351.983C221.29 354.265 220.903 356.66 219.703 358.655V358.66Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M218.978 404.81C214.617 407.007 198 405.49 198 405.49C198 405.49 206.66 391.225 211.021 389.028C212.06 388.478 213.199 388.14 214.37 388.035C215.541 387.93 216.721 388.06 217.842 388.417C218.962 388.773 220 389.35 220.895 390.113C221.79 390.876 222.523 391.809 223.053 392.859C223.582 393.908 223.897 395.053 223.979 396.226C224.06 397.399 223.907 398.576 223.528 399.689C223.149 400.802 222.552 401.828 221.771 402.707C220.991 403.587 220.043 404.301 218.982 404.81H218.978Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M210.39 439.829C206.5 442.701 190 444 190 444C190 444 196.074 428.634 199.964 425.758C200.889 425.063 201.942 424.558 203.063 424.272C204.184 423.986 205.351 423.925 206.496 424.091C207.641 424.258 208.742 424.649 209.735 425.242C210.728 425.835 211.593 426.618 212.281 427.547C212.969 428.476 213.467 429.531 213.744 430.653C214.022 431.774 214.074 432.939 213.898 434.081C213.722 435.223 213.322 436.319 212.72 437.305C212.118 438.292 211.326 439.149 210.39 439.829Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M183.506 371.05C186.953 374.456 203 378 203 378C203 378 199.067 361.77 195.62 358.359C193.953 356.787 191.747 355.942 189.475 356.003C187.203 356.064 185.045 357.027 183.461 358.685C181.878 360.343 180.994 362.566 181 364.878C181.006 367.191 181.9 369.409 183.492 371.059L183.506 371.05Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M174.022 411.81C178.383 414.007 195 412.49 195 412.49C195 412.49 186.34 398.225 181.979 396.028C180.94 395.478 179.801 395.14 178.63 395.035C177.459 394.93 176.279 395.06 175.158 395.417C174.038 395.774 173 396.35 172.105 397.113C171.21 397.876 170.477 398.809 169.947 399.859C169.418 400.908 169.103 402.053 169.021 403.226C168.94 404.399 169.093 405.576 169.472 406.689C169.851 407.802 170.448 408.828 171.229 409.707C172.009 410.587 172.957 411.301 174.018 411.81H174.022Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M170.61 452.829C174.5 455.701 191 457 191 457C191 457 184.926 441.634 181.036 438.758C180.111 438.063 179.058 437.558 177.937 437.272C176.816 436.986 175.649 436.925 174.504 437.091C173.359 437.258 172.258 437.649 171.265 438.242C170.272 438.835 169.407 439.618 168.719 440.547C168.031 441.476 167.533 442.531 167.256 443.653C166.978 444.774 166.926 445.939 167.102 447.081C167.278 448.223 167.678 449.319 168.28 450.305C168.882 451.292 169.674 452.149 170.61 452.829Z"
          fill="black"
        />
        <path
          d="M321.581 465C321.581 465 309.944 425.216 323.765 395.947C329.567 383.66 331.491 369.862 328.812 356.524C327.531 350.192 325.676 343.991 323.27 338"
          stroke="#535461"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M332 320.024C332 324.896 323.001 339 323.001 339C323.001 339 314.002 324.877 314.002 320.024C313.975 318.849 314.187 317.68 314.626 316.586C315.066 315.493 315.724 314.497 316.562 313.656C317.4 312.815 318.402 312.147 319.507 311.691C320.612 311.235 321.8 311 322.999 311C324.198 311 325.385 311.235 326.491 311.691C327.596 312.147 328.597 312.815 329.435 313.656C330.273 314.497 330.932 315.493 331.371 316.586C331.811 317.68 332.023 318.849 331.995 320.024H332Z"
          fill="#52BD43"
        />
        <path
          d="M346.703 346.66C344.059 350.82 329 358 329 358C329 358 329.275 341.067 331.919 336.908C333.216 334.976 335.203 333.637 337.456 333.176C339.709 332.715 342.049 333.169 343.978 334.44C345.907 335.712 347.272 337.701 347.781 339.983C348.29 342.265 347.903 344.66 346.703 346.655V346.66Z"
          fill="#52BD43"
        />
        <path
          d="M345.978 392.81C341.617 395.007 325 393.49 325 393.49C325 393.49 333.66 379.225 338.021 377.028C339.06 376.478 340.199 376.14 341.37 376.035C342.541 375.93 343.721 376.06 344.842 376.417C345.962 376.773 347 377.35 347.895 378.113C348.79 378.876 349.523 379.809 350.053 380.859C350.582 381.908 350.897 383.053 350.979 384.226C351.06 385.399 350.907 386.576 350.528 387.689C350.149 388.802 349.552 389.828 348.771 390.707C347.991 391.587 347.043 392.301 345.982 392.81H345.978Z"
          fill="#52BD43"
        />
        <path
          d="M337.39 427.829C333.5 430.701 317 432 317 432C317 432 323.074 416.634 326.964 413.758C327.889 413.063 328.942 412.558 330.063 412.272C331.184 411.986 332.351 411.925 333.496 412.091C334.641 412.258 335.742 412.649 336.735 413.242C337.728 413.835 338.593 414.618 339.281 415.547C339.969 416.476 340.467 417.531 340.744 418.653C341.022 419.774 341.074 420.939 340.898 422.081C340.722 423.223 340.322 424.319 339.72 425.305C339.118 426.292 338.326 427.149 337.39 427.829Z"
          fill="#52BD43"
        />
        <path
          d="M310.506 359.05C313.953 362.456 330 366 330 366C330 366 326.067 349.77 322.62 346.359C320.953 344.787 318.747 343.942 316.475 344.003C314.203 344.064 312.045 345.027 310.461 346.685C308.878 348.343 307.994 350.566 308 352.878C308.006 355.191 308.9 357.409 310.492 359.059L310.506 359.05Z"
          fill="#52BD43"
        />
        <path
          d="M301.022 399.81C305.383 402.007 322 400.49 322 400.49C322 400.49 313.34 386.225 308.979 384.028C307.94 383.478 306.801 383.14 305.63 383.035C304.459 382.93 303.279 383.06 302.158 383.417C301.038 383.774 300 384.35 299.105 385.113C298.21 385.876 297.477 386.809 296.947 387.859C296.418 388.908 296.103 390.053 296.021 391.226C295.94 392.399 296.093 393.576 296.472 394.689C296.851 395.802 297.448 396.828 298.229 397.707C299.009 398.587 299.957 399.301 301.018 399.81H301.022Z"
          fill="#52BD43"
        />
        <path
          d="M297.61 440.829C301.5 443.701 318 445 318 445C318 445 311.926 429.634 308.036 426.758C307.111 426.063 306.058 425.558 304.937 425.272C303.816 424.986 302.649 424.925 301.504 425.091C300.359 425.258 299.258 425.649 298.265 426.242C297.272 426.835 296.407 427.618 295.719 428.547C295.031 429.476 294.533 430.531 294.256 431.653C293.978 432.774 293.926 433.939 294.102 435.081C294.278 436.223 294.678 437.319 295.28 438.305C295.882 439.292 296.674 440.149 297.61 440.829Z"
          fill="#52BD43"
        />
        <path
          opacity="0.25"
          d="M332 320.024C332 324.896 323.001 339 323.001 339C323.001 339 314.002 324.877 314.002 320.024C313.975 318.849 314.187 317.68 314.626 316.586C315.066 315.493 315.724 314.497 316.562 313.656C317.4 312.815 318.402 312.147 319.507 311.691C320.612 311.235 321.8 311 322.999 311C324.198 311 325.385 311.235 326.491 311.691C327.596 312.147 328.597 312.815 329.435 313.656C330.273 314.497 330.932 315.493 331.371 316.586C331.811 317.68 332.023 318.849 331.995 320.024H332Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M346.703 346.66C344.059 350.82 329 358 329 358C329 358 329.275 341.067 331.919 336.908C333.216 334.976 335.203 333.637 337.456 333.176C339.709 332.715 342.049 333.169 343.978 334.44C345.907 335.712 347.272 337.701 347.781 339.983C348.29 342.265 347.903 344.66 346.703 346.655V346.66Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M345.978 392.81C341.617 395.007 325 393.49 325 393.49C325 393.49 333.66 379.225 338.021 377.028C339.06 376.478 340.199 376.14 341.37 376.035C342.541 375.93 343.721 376.06 344.842 376.417C345.962 376.773 347 377.35 347.895 378.113C348.79 378.876 349.523 379.809 350.053 380.859C350.582 381.908 350.897 383.053 350.979 384.226C351.06 385.399 350.907 386.576 350.528 387.689C350.149 388.802 349.552 389.828 348.771 390.707C347.991 391.587 347.043 392.301 345.982 392.81H345.978Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M337.39 427.829C333.5 430.701 317 432 317 432C317 432 323.074 416.634 326.964 413.758C327.889 413.063 328.942 412.558 330.063 412.272C331.184 411.986 332.351 411.925 333.496 412.091C334.641 412.258 335.742 412.649 336.735 413.242C337.728 413.835 338.593 414.618 339.281 415.547C339.969 416.476 340.467 417.531 340.744 418.653C341.022 419.774 341.074 420.939 340.898 422.081C340.722 423.223 340.322 424.319 339.72 425.305C339.118 426.292 338.326 427.149 337.39 427.829Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M310.506 359.05C313.953 362.456 330 366 330 366C330 366 326.067 349.77 322.62 346.359C320.953 344.787 318.747 343.942 316.475 344.003C314.203 344.064 312.045 345.027 310.461 346.685C308.878 348.343 307.994 350.566 308 352.878C308.006 355.191 308.9 357.409 310.492 359.059L310.506 359.05Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M301.022 399.81C305.383 402.007 322 400.49 322 400.49C322 400.49 313.34 386.225 308.979 384.028C307.94 383.478 306.801 383.14 305.63 383.035C304.459 382.93 303.279 383.06 302.158 383.417C301.038 383.774 300 384.35 299.105 385.113C298.21 385.876 297.477 386.809 296.947 387.859C296.418 388.908 296.103 390.053 296.021 391.226C295.94 392.399 296.093 393.576 296.472 394.689C296.851 395.802 297.448 396.828 298.229 397.707C299.009 398.587 299.957 399.301 301.018 399.81H301.022Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M297.61 440.829C301.5 443.701 318 445 318 445C318 445 311.926 429.634 308.036 426.758C307.111 426.063 306.058 425.558 304.937 425.272C303.816 424.986 302.649 424.925 301.504 425.091C300.359 425.258 299.258 425.649 298.265 426.242C297.272 426.835 296.407 427.618 295.719 428.547C295.031 429.476 294.533 430.531 294.256 431.653C293.978 432.774 293.926 433.939 294.102 435.081C294.278 436.223 294.678 437.319 295.28 438.305C295.882 439.292 296.674 440.149 297.61 440.829Z"
          fill="black"
        />
        <path
          d="M95.3491 477.107C95.3491 477.107 79.5135 423.278 98.321 383.647C106.216 367.02 108.835 348.349 105.189 330.301C103.446 321.732 100.921 313.341 97.647 305.234"
          stroke="#535461"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M109.349 280.225C109.349 286.826 97.416 305.935 97.416 305.935C97.416 305.935 85.4831 286.801 85.4831 280.225C85.4463 278.633 85.7276 277.05 86.3105 275.569C86.8935 274.087 87.7663 272.737 88.8777 271.598C89.9891 270.459 91.3167 269.554 92.7824 268.936C94.2481 268.318 95.8224 268 97.4127 268C99.0031 268 100.577 268.318 102.043 268.936C103.509 269.554 104.836 270.459 105.948 271.598C107.059 272.737 107.932 274.087 108.515 275.569C109.098 277.05 109.379 278.633 109.342 280.225H109.349Z"
          fill="#52BD43"
        />
        <path
          d="M129.068 316.771C125.486 322.311 105.087 331.875 105.087 331.875C105.087 331.875 105.459 309.322 109.041 303.782C109.872 302.422 110.967 301.243 112.261 300.313C113.554 299.384 115.021 298.723 116.574 298.37C118.126 298.016 119.734 297.978 121.302 298.256C122.869 298.535 124.366 299.125 125.702 299.991C127.039 300.858 128.189 301.983 129.084 303.302C129.98 304.62 130.603 306.105 130.916 307.668C131.23 309.231 131.228 310.841 130.91 312.403C130.593 313.966 129.966 315.449 129.068 316.765V316.771Z"
          fill="#52BD43"
        />
        <path
          d="M128.208 379.225C122.321 382.194 99.8936 380.144 99.8936 380.144C99.8936 380.144 111.583 360.862 117.469 357.893C120.285 356.54 123.519 356.347 126.476 357.356C129.433 358.364 131.877 360.493 133.283 363.285C134.69 366.078 134.947 369.312 133.999 372.292C133.051 375.272 130.974 377.761 128.214 379.225H128.208Z"
          fill="#52BD43"
        />
        <path
          d="M116.93 426.331C111.634 430.251 89.174 432.025 89.174 432.025C89.174 432.025 97.4416 411.047 102.737 407.12C105.283 405.265 108.458 404.493 111.57 404.972C114.681 405.451 117.478 407.142 119.35 409.676C121.222 412.211 122.019 415.383 121.565 418.503C121.112 421.622 119.446 424.436 116.93 426.331Z"
          fill="#52BD43"
        />
        <path
          d="M80.0335 333.154C84.7578 337.75 106.781 342.513 106.781 342.513C106.781 342.513 101.389 320.615 96.6649 316.013C94.3792 313.893 91.3561 312.752 88.2418 312.835C85.1274 312.917 82.1686 314.216 79.9977 316.453C77.8268 318.691 76.6159 321.69 76.6238 324.809C76.6317 327.929 77.8577 330.921 80.0399 333.148L80.0335 333.154Z"
          fill="#52BD43"
        />
        <path
          d="M67.5679 388.41C73.4541 391.379 95.8818 389.329 95.8818 389.329C95.8818 389.329 84.193 370.047 78.3068 367.077C75.4929 365.745 72.2705 365.567 69.3271 366.58C66.3836 367.594 63.9521 369.718 62.5511 372.501C61.1501 375.284 60.8905 378.505 61.8277 381.477C62.7649 384.449 64.8248 386.937 67.5679 388.41Z"
          fill="#52BD43"
        />
        <path
          d="M62.7601 443.787C68.0557 447.708 90.5156 449.482 90.5156 449.482C90.5156 449.482 82.248 428.503 76.9524 424.563C74.4064 422.709 71.2318 421.937 68.1201 422.416C65.0083 422.895 62.2117 424.586 60.3394 427.12C58.4672 429.655 57.671 432.827 58.1244 435.947C58.5779 439.066 60.2441 441.88 62.7601 443.775V443.787Z"
          fill="#52BD43"
        />
        <path
          opacity="0.25"
          d="M109.349 280.225C109.349 286.826 97.416 305.935 97.416 305.935C97.416 305.935 85.4831 286.801 85.4831 280.225C85.4463 278.633 85.7276 277.05 86.3105 275.569C86.8935 274.087 87.7663 272.737 88.8777 271.598C89.9891 270.459 91.3167 269.554 92.7824 268.936C94.2481 268.318 95.8224 268 97.4127 268C99.0031 268 100.577 268.318 102.043 268.936C103.509 269.554 104.836 270.459 105.948 271.598C107.059 272.737 107.932 274.087 108.515 275.569C109.098 277.05 109.379 278.633 109.342 280.225H109.349Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M129.068 316.771C125.486 322.311 105.087 331.875 105.087 331.875C105.087 331.875 105.459 309.322 109.041 303.782C109.872 302.422 110.967 301.243 112.261 300.313C113.554 299.384 115.021 298.723 116.574 298.37C118.126 298.016 119.734 297.978 121.302 298.256C122.869 298.535 124.366 299.125 125.702 299.991C127.039 300.858 128.189 301.983 129.084 303.302C129.98 304.62 130.603 306.105 130.916 307.668C131.23 309.231 131.228 310.841 130.91 312.403C130.593 313.966 129.966 315.449 129.068 316.765V316.771Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M128.208 379.225C122.321 382.194 99.8936 380.144 99.8936 380.144C99.8936 380.144 111.583 360.862 117.469 357.893C120.285 356.54 123.519 356.347 126.476 357.356C129.433 358.364 131.877 360.493 133.283 363.285C134.69 366.078 134.947 369.312 133.999 372.292C133.051 375.272 130.974 377.761 128.214 379.225H128.208Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M116.93 426.331C111.634 430.251 89.174 432.025 89.174 432.025C89.174 432.025 97.4416 411.047 102.737 407.12C105.283 405.265 108.458 404.493 111.57 404.972C114.681 405.451 117.478 407.142 119.35 409.676C121.222 412.211 122.019 415.383 121.565 418.503C121.112 421.622 119.446 424.436 116.93 426.331Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M80.0335 333.154C84.7578 337.75 106.781 342.513 106.781 342.513C106.781 342.513 101.389 320.615 96.6649 316.013C94.3792 313.893 91.3561 312.752 88.2418 312.835C85.1274 312.917 82.1686 314.216 79.9977 316.453C77.8268 318.691 76.6159 321.69 76.6238 324.809C76.6317 327.929 77.8577 330.921 80.0399 333.148L80.0335 333.154Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M67.5679 388.41C73.4541 391.379 95.8818 389.329 95.8818 389.329C95.8818 389.329 84.193 370.047 78.3068 367.077C75.4929 365.745 72.2705 365.567 69.3271 366.58C66.3836 367.594 63.9521 369.718 62.5511 372.501C61.1501 375.284 60.8905 378.505 61.8277 381.477C62.7649 384.449 64.8248 386.937 67.5679 388.41Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M62.7601 443.787C68.0557 447.708 90.5156 449.482 90.5156 449.482C90.5156 449.482 82.248 428.503 76.9524 424.563C74.4064 422.709 71.2318 421.937 68.1201 422.416C65.0083 422.895 62.2117 424.586 60.3394 427.12C58.4672 429.655 57.671 432.827 58.1244 435.947C58.5779 439.066 60.2441 441.88 62.7601 443.775V443.787Z"
          fill="black"
        />
        <path
          d="M255.512 424.659C255.512 424.659 246.937 395.511 257.117 374.063C261.444 365.093 262.748 354.96 260.834 345.185C259.889 340.551 258.523 336.014 256.751 331.63"
          stroke="#535461"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M263.087 318.055C263.087 321.629 256.629 331.983 256.629 331.983C256.629 331.983 250.172 321.642 250.172 318.055C250.172 317.206 250.339 316.365 250.664 315.58C250.988 314.795 251.464 314.082 252.064 313.481C252.664 312.88 253.376 312.404 254.16 312.079C254.944 311.754 255.784 311.586 256.632 311.586C257.481 311.586 258.321 311.754 259.105 312.079C259.889 312.404 260.601 312.88 261.201 313.481C261.801 314.082 262.277 314.795 262.601 315.58C262.926 316.365 263.093 317.206 263.093 318.055H263.087Z"
          fill="#52BD43"
        />
        <path
          d="M273.768 337.858C271.842 340.853 260.782 346.033 260.782 346.033C260.782 346.033 260.981 333.821 262.92 330.826C263.851 329.387 265.315 328.377 266.99 328.018C268.665 327.659 270.414 327.982 271.852 328.914C273.289 329.847 274.298 331.313 274.656 332.99C275.015 334.667 274.693 336.418 273.761 337.858H273.768Z"
          fill="#52BD43"
        />
        <path
          d="M273.299 371.666C270.09 373.272 257.971 372.16 257.971 372.16C257.971 372.16 264.3 361.723 267.484 360.116C269.016 359.344 270.792 359.212 272.42 359.751C274.049 360.29 275.397 361.455 276.168 362.989C276.94 364.523 277.071 366.301 276.533 367.932C275.994 369.563 274.831 370.913 273.299 371.685V371.666Z"
          fill="#52BD43"
        />
        <path
          d="M267.195 397.169C264.326 399.29 252.168 400.254 252.168 400.254C252.168 400.254 256.661 388.897 259.511 386.757C260.889 385.734 262.615 385.301 264.311 385.553C266.007 385.805 267.534 386.721 268.556 388.1C269.577 389.479 270.01 391.208 269.758 392.906C269.506 394.605 268.591 396.133 267.214 397.156L267.195 397.169Z"
          fill="#52BD43"
        />
        <path
          d="M247.206 346.728C249.774 349.215 261.687 351.792 261.687 351.792C261.687 351.792 258.76 339.908 256.193 337.447C254.954 336.308 253.319 335.698 251.638 335.747C249.957 335.796 248.361 336.5 247.19 337.709C246.019 338.918 245.365 340.537 245.368 342.222C245.372 343.906 246.031 345.523 247.206 346.728Z"
          fill="#52BD43"
        />
        <path
          d="M240.46 376.64C243.669 378.247 255.788 377.135 255.788 377.135C255.788 377.135 249.459 366.697 246.275 365.09C244.743 364.318 242.968 364.187 241.339 364.726C239.71 365.265 238.362 366.429 237.591 367.963C236.819 369.498 236.689 371.276 237.227 372.906C237.765 374.537 238.928 375.887 240.46 376.66V376.64Z"
          fill="#52BD43"
        />
        <path
          d="M237.867 406.624C240.736 408.745 252.893 409.709 252.893 409.709C252.893 409.709 248.4 398.352 245.55 396.211C244.868 395.705 244.093 395.338 243.27 395.132C242.446 394.925 241.59 394.883 240.75 395.008C239.054 395.259 237.527 396.176 236.506 397.555C235.484 398.934 235.052 400.663 235.304 402.361C235.555 404.059 236.47 405.588 237.847 406.611L237.867 406.624Z"
          fill="#52BD43"
        />
        <path
          opacity="0.5"
          d="M263.087 318.055C263.087 321.629 256.629 331.983 256.629 331.983C256.629 331.983 250.172 321.642 250.172 318.055C250.172 317.206 250.339 316.365 250.664 315.58C250.988 314.795 251.464 314.082 252.064 313.481C252.664 312.88 253.376 312.404 254.16 312.079C254.944 311.754 255.784 311.586 256.632 311.586C257.481 311.586 258.321 311.754 259.105 312.079C259.889 312.404 260.601 312.88 261.201 313.481C261.801 314.082 262.277 314.795 262.601 315.58C262.926 316.365 263.093 317.206 263.093 318.055H263.087Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M273.768 337.858C271.842 340.853 260.782 346.033 260.782 346.033C260.782 346.033 260.981 333.821 262.92 330.826C263.851 329.387 265.315 328.377 266.99 328.018C268.665 327.659 270.414 327.982 271.852 328.914C273.289 329.847 274.298 331.313 274.656 332.99C275.015 334.667 274.693 336.418 273.761 337.858H273.768Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M273.299 371.666C270.09 373.272 257.971 372.16 257.971 372.16C257.971 372.16 264.3 361.723 267.484 360.116C269.016 359.344 270.792 359.212 272.42 359.751C274.049 360.29 275.397 361.455 276.168 362.989C276.94 364.523 277.071 366.301 276.533 367.932C275.994 369.563 274.831 370.913 273.299 371.685V371.666Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M267.195 397.169C264.326 399.29 252.168 400.254 252.168 400.254C252.168 400.254 256.661 388.897 259.511 386.757C260.889 385.734 262.615 385.301 264.311 385.553C266.007 385.805 267.534 386.721 268.556 388.1C269.577 389.479 270.01 391.208 269.758 392.906C269.506 394.605 268.591 396.133 267.214 397.156L267.195 397.169Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M247.206 346.728C249.774 349.215 261.687 351.792 261.687 351.792C261.687 351.792 258.76 339.908 256.193 337.447C254.954 336.308 253.319 335.698 251.638 335.747C249.957 335.796 248.361 336.5 247.19 337.709C246.019 338.918 245.365 340.537 245.368 342.222C245.372 343.906 246.031 345.523 247.206 346.728Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M240.46 376.64C243.669 378.247 255.788 377.135 255.788 377.135C255.788 377.135 249.459 366.697 246.275 365.09C244.743 364.318 242.968 364.187 241.339 364.726C239.71 365.265 238.362 366.429 237.591 367.963C236.819 369.498 236.689 371.276 237.227 372.906C237.765 374.537 238.928 375.887 240.46 376.66V376.64Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M237.867 406.624C240.736 408.745 252.893 409.709 252.893 409.709C252.893 409.709 248.4 398.352 245.55 396.211C244.868 395.705 244.093 395.338 243.27 395.132C242.446 394.925 241.59 394.883 240.75 395.008C239.054 395.259 237.527 396.176 236.506 397.555C235.484 398.934 235.052 400.663 235.304 402.361C235.555 404.059 236.47 405.588 237.847 406.611L237.867 406.624Z"
          fill="white"
        />
        <path
          d="M196.426 65.3978V137.506L229.753 94.5971"
          stroke="#535461"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M162.502 88.0413L196.426 137.506"
          stroke="#535461"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M257.727 56.9202C257.724 52.6847 256.721 48.5099 254.798 44.7368C252.876 40.9638 250.09 37.6995 246.667 35.2106C243.245 32.7217 239.283 31.0788 235.105 30.4161C230.927 29.7534 226.652 30.0896 222.628 31.3974C222.849 29.8884 222.963 28.3656 222.968 26.8404C222.964 19.8922 220.268 13.2165 215.448 8.21748C210.629 3.21846 204.061 0.285662 197.126 0.0359157C190.192 -0.213831 183.43 2.23893 178.265 6.87835C173.099 11.5178 169.931 17.9823 169.428 24.9122C165.403 23.6007 161.126 23.2616 156.946 23.9225C152.765 24.5835 148.8 26.2257 145.375 28.715C141.95 31.2044 139.162 34.47 137.239 38.2451C135.315 42.0201 134.312 46.1973 134.31 50.4351C134.31 63.9324 144.214 83.0537 157.129 86.428L159.138 87.5785L158.047 91.3192H164.505L163.118 87.5849L165.114 86.4409C175.089 83.8185 183.274 71.7866 186.477 60.2432C188.231 61.4134 190.162 62.2923 192.196 62.8462L194.205 63.9967L193.114 67.7374H199.571L198.166 63.9903L200.162 62.8462C201.648 62.4477 203.08 61.867 204.424 61.1173C206.151 74.0683 215.336 89.873 226.948 92.926L228.957 94.0765L227.866 97.8172H234.323L232.918 94.0701L234.914 92.926C247.848 89.5388 257.727 70.3854 257.727 56.9202Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <path
          d="M165.525 89.963H159.28L160.371 86.2095H164.119L165.525 89.963Z"
          fill="#52BD43"
        />
        <path
          d="M188.152 50.3836C188.152 64.7294 176.54 85.7338 162.213 85.7338C147.886 85.7338 136.274 64.7165 136.274 50.3836C136.381 43.5668 139.161 37.0655 144.013 32.2828C148.865 27.5001 155.401 24.8195 162.21 24.8195C169.019 24.8195 175.554 27.5001 180.407 32.2828C185.259 37.0655 188.038 43.5668 188.146 50.3836H188.152Z"
          fill="#52BD43"
        />
        <path
          opacity="0.2"
          d="M188.152 50.3836C188.152 64.7294 176.54 85.7338 162.213 85.7338C147.886 85.7338 136.274 64.7165 136.274 50.3836C136.381 43.5668 139.161 37.0655 144.013 32.2828C148.865 27.5001 155.401 24.8195 162.21 24.8195C169.019 24.8195 175.554 27.5001 180.407 32.2828C185.259 37.0655 188.038 43.5668 188.146 50.3836H188.152Z"
          fill="white"
        />
        <path
          d="M164.119 86.3701H160.371L158.182 85.1168H166.308L164.119 86.3701Z"
          fill="#535461"
        />
        <path
          opacity="0.1"
          d="M152.848 30.1954C152.848 30.1954 141.936 36.1407 140.35 48.0312L152.848 30.1954Z"
          fill="white"
        />
        <path
          d="M199.443 67.1332H193.197L194.289 63.3796H198.037L199.443 67.1332Z"
          fill="#52BD43"
        />
        <path
          opacity="0.07"
          d="M199.443 67.1332H193.197L194.289 63.3796H198.037L199.443 67.1332Z"
          fill="black"
        />
        <path
          d="M222.102 27.541C222.102 41.8867 210.49 62.8912 196.163 62.8912C181.836 62.8912 170.224 41.8739 170.224 27.541C170.331 20.7242 173.111 14.2229 177.963 9.44016C182.815 4.65743 189.351 1.97681 196.16 1.97681C202.968 1.97681 209.504 4.65743 214.356 9.44016C219.209 14.2229 221.988 20.7242 222.095 27.541H222.102Z"
          fill="#52BD43"
        />
        <path
          d="M198.037 63.5339H194.289L192.1 62.287H200.226L198.037 63.5339Z"
          fill="#535461"
        />
        <path
          opacity="0.1"
          d="M434.785 45.3657C434.785 45.3657 423.873 51.311 422.287 63.2015L434.785 45.3657Z"
          fill="white"
        />
        <path
          d="M233.078 96.2425H226.826L227.924 92.4889H231.672L233.078 96.2425Z"
          fill="#52BD43"
        />
        <path
          d="M255.731 56.6567C255.731 71.0025 244.119 92.0069 229.792 92.0069C215.465 92.0069 203.853 70.9896 203.853 56.6567C203.96 49.8399 206.739 43.3386 211.592 38.5559C216.444 33.7732 222.98 31.0925 229.788 31.0925C236.597 31.0925 243.133 33.7732 247.985 38.5559C252.837 43.3386 255.617 49.8399 255.724 56.6567H255.731Z"
          fill="#52BD43"
        />
        <path
          opacity="0.2"
          d="M255.731 56.6567C255.731 71.0025 244.119 92.0069 229.792 92.0069C215.465 92.0069 203.853 70.9896 203.853 56.6567C203.96 49.8399 206.739 43.3386 211.592 38.5559C216.444 33.7732 222.98 31.0925 229.788 31.0925C236.597 31.0925 243.133 33.7732 247.985 38.5559C252.837 43.3386 255.617 49.8399 255.724 56.6567H255.731Z"
          fill="white"
        />
        <path
          d="M231.672 92.6497H227.924L225.735 91.3964H233.861L231.672 92.6497Z"
          fill="#535461"
        />
        <path
          opacity="0.1"
          d="M220.42 36.475C220.42 36.475 209.508 42.4202 207.922 54.3108L220.42 36.475Z"
          fill="white"
        />
        <path
          opacity="0.03"
          d="M199.25 268.893C199.25 268.893 200.534 275.166 208.648 275.796L199.25 268.893Z"
          fill="black"
        />
        <path
          opacity="0.03"
          d="M156.648 327.054C156.648 327.054 166.276 332.491 177.111 332.073L156.648 327.054Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M137.404 169.45H134.74L135.202 167.843H136.807L137.404 169.45Z"
          fill="#52BD43"
        />
        <path
          opacity="0.3"
          d="M147.071 152.552C147.071 158.658 142.115 167.656 136.005 167.656C129.894 167.656 124.938 158.658 124.938 152.571C124.938 149.633 126.104 146.814 128.18 144.736C130.255 142.658 133.07 141.491 136.005 141.491C138.939 141.491 141.754 142.658 143.83 144.736C145.905 146.814 147.071 149.633 147.071 152.571V152.552Z"
          fill="#52BD43"
        />
        <path
          d="M136.807 167.913H135.202L134.271 167.38H137.738L136.807 167.913Z"
          fill="#535461"
        />
        <path
          opacity="0.1"
          d="M131.999 143.946C131.999 143.946 127.333 146.485 126.665 151.556L131.999 143.946Z"
          fill="white"
        />
        <path
          d="M136.281 167.611C135.575 168.391 135.053 169.318 134.751 170.325C134.448 171.333 134.375 172.395 134.535 173.434C135.119 176.931 138.026 180.427 137.442 184.502C136.858 188.577 135.112 190.93 135.112 192.659"
          stroke="#535461"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.3"
          d="M425.075 204.536H422.405L422.873 202.929H424.472L425.075 204.536Z"
          fill="#52BD43"
        />
        <path
          opacity="0.3"
          d="M272.133 222.963C272.133 229.082 267.177 238.048 261.066 238.048C254.955 238.048 250 229.05 250 222.963C250 220.024 251.166 217.206 253.241 215.128C255.317 213.05 258.131 211.882 261.066 211.882C264.001 211.882 266.816 213.05 268.891 215.128C270.967 217.206 272.133 220.024 272.133 222.963Z"
          fill="#52BD43"
        />
        <path
          d="M424.472 203H422.873L421.943 202.467H425.409L424.472 203Z"
          fill="#535461"
        />
        <path
          opacity="0.1"
          d="M419.67 179.033C419.67 179.033 415.004 181.571 414.336 186.643L419.67 179.033Z"
          fill="white"
        />
        <path
          d="M261.349 238.022C260.643 238.801 260.121 239.729 259.819 240.736C259.517 241.743 259.443 242.806 259.603 243.845C260.187 247.342 263.095 250.838 262.511 254.913C261.926 258.988 260.181 261.34 260.181 263.069"
          stroke="#535461"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M197.537 136C193.858 140.139 191.131 145.062 189.556 150.412C187.98 155.761 187.595 161.401 188.429 166.921C191.476 185.487 206.645 204.053 203.598 225.691C200.551 247.328 191.443 259.819 191.443 269"
          stroke="#535461"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.3"
          d="M474.809 185.415L472.197 184.862L472.986 183.39L474.552 183.725L474.809 185.415Z"
          fill="#52BD43"
        />
        <path
          opacity="0.3"
          d="M325.153 206.194C323.869 212.184 317.187 219.929 311.205 218.663C305.222 217.397 302.218 207.595 303.502 201.611C304.149 198.777 305.882 196.311 308.329 194.745C310.775 193.179 313.738 192.638 316.579 193.24C319.419 193.841 321.91 195.536 323.514 197.959C325.118 200.383 325.707 203.34 325.153 206.194Z"
          fill="#52BD43"
        />
        <path
          d="M474.539 183.789L472.973 183.461L472.171 182.741L475.56 183.461L474.539 183.789Z"
          fill="#535461"
        />
        <path
          opacity="0.1"
          d="M474.796 159.346C474.796 159.346 469.706 160.863 468.005 165.683L474.796 159.346Z"
          fill="white"
        />
        <path
          d="M311.487 218.708C310.636 219.324 309.932 220.123 309.428 221.045C308.924 221.968 308.632 222.992 308.573 224.043C308.419 227.584 310.543 231.607 309.131 235.477C307.719 239.346 305.53 241.261 305.171 242.971"
          stroke="#535461"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="196.022"
          y1="97.8236"
          x2="196.022"
          y2="-4.56473e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="488" height="505" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ballons;
